import { eSurveyQuestionTypes } from "./../../general/enums/survey-question-type.enum";
import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService } from "ngx-bootstrap/modal";
import { ToastrService } from "ngx-toastr";
import { SurveyTypes } from "src/app/general/enums/survey-types.enum";
import { AuthService } from "src/services/auth.service";
import { HomeService } from "src/services/home.service";
import { LanguageService } from "src/services/language.service";
import { SurveyService } from "src/services/survey.service";
import { ImagePreviewerComponent } from "../answer-survey/image-previewer/image-previewer.component";
import { SurveyNameComponent } from "../survey-name/survey-name.component";
import { eLanguage } from "src/app/general/enums/language.enum";

@Component({
  selector: "app-answer-survey-two",
  templateUrl: "./answer-survey-two.component.html",
  styleUrls: ["./answer-survey-two.component.css"],
})
export class AnswerSurveyTwoComponent implements OnInit {
  showLoader = false;
  likes: any;
  surveyForm: FormGroup;
  singleQuestion: FormArray;
  dummySurvey: any;
  surveyId: any;
  startDate: any;
  endDate: any;
  userId: any;
  gender: any;
  answerUserName: any;
  eQuesTypes = eSurveyQuestionTypes;
  currentQuestionNo: number;
  questionValidation: boolean = false;
  questionAnimationClass = "d-none";
  lang: any;
  displayStates = {
    invite: true,
    surveyDetails: false,
    question: false,
  };

  constructor(
    private _router: Router,
    private _fb: FormBuilder,
    private _ac: ActivatedRoute,
    private authService: AuthService,
    private surveyService: SurveyService,
    private toasterService: ToastrService,
    private bsModalService: BsModalService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private homeService: HomeService
  ) {
    let currentUser = this.authService.currentUser;
    const languages = [eLanguage.Arabic, eLanguage.English];
    this.lang =
      this._ac.snapshot.params?.lang &&
        languages.includes(this._ac.snapshot.params?.lang)
        ? this._ac.snapshot.params?.lang
        : eLanguage.English;
    console.log(this.lang, "current language");
    const langPrefix =
      this.lang === eLanguage.Arabic
        ? eLanguage.Short_Arabic
        : eLanguage.Short_English;
    if (this.languageService.getLanguageOrDefault() !== langPrefix) {
      this.languageService.setLanguage(langPrefix);
      window.location.reload();
    }
    currentUser = currentUser
      ? { ...currentUser, language: this.lang }
      : { language: this.lang };
    this.authService.setCurrentUser(currentUser, null);
  }

  async ngOnInit() {
    this.singleQuestion = this._fb.array([]);
    this._ac.snapshot.params?.surveyId
      ? await this.getSurveyById()
      : this.surveyInIt();
    setTimeout(() => (this.displayStates.surveyDetails = true), 1000);
  }

  public surveyInIt() {
    const surveyInfo = this.surveyService.getLocalSurveyUserInfo();
    const getSurvey = this.surveyService.getLocalSurveyModal();
    this.likes = getSurvey.likes;
    this.startDate = getSurvey.startDate;
    this.endDate = getSurvey.endDate;
    const survey = this.surveyService.getLocalSurvey();
    let surveyData = {
      ...getSurvey,
      ...survey,
      ...surveyInfo,
    };

    this.gender = surveyInfo.gender;
    this.surveyId = getSurvey._id;
    this.userId = getSurvey.userId;
    this.answerUserName = surveyInfo.name;
    this.createSurveyFrom(surveyData);
    this.nextQuestion(1);
  }

  private createSurveyFrom(data?: any) {
    this.surveyForm = this._fb.group({
      surveyId: new FormControl(data._id ? data._id : "", [
        Validators.required,
      ]),
      channel: new FormControl(data.channel ? data.channel : "", [
        Validators.required,
      ]),
      country: new FormControl(data.country ? data.country : "", [
        Validators.required,
      ]),
      cityId: new FormControl(data.cityId ? data.cityId : "", [
        Validators.required,
      ]),
      age: new FormControl(data.age ? data.age : "", [Validators.required]),
      gender: new FormControl(data.gender ? data.gender : "", [
        Validators.required,
      ]),
      name: new FormControl(data.name ? data.name : "", [Validators.required]),
      title: new FormControl(data.title ? data.title : "", [
        Validators.required,
      ]),
      surveyType: new FormControl(
        data?.surveyType ? data?.surveyType : SurveyTypes.NORMAL
      ),
      categoryId: new FormControl(data.categoryId ? data.categoryId : null, [
        Validators.required,
      ]),
      description: new FormControl(data.description ? data.description : "", [
        Validators.required,
      ]),
      language: new FormControl(data.language ? data.language : "en", [
        Validators.required,
      ]),
      image: new FormControl(data.image ? data.image : null),
      imageUrl: new FormControl(data.surveyImage ? data.surveyImage : null),
      totalPoints: new FormControl(data.totalPoints ? data.totalPoints : 0),
      exchangeRate: new FormControl(data.exchangeRate ? data.exchangeRate : ""),
      questionRate: new FormControl(data.questionRate ? data.questionRate : ""),
      uploadQuestion: new FormControl(
        data.uploadQuestion ? data.uploadQuestion : ""
      ),
      contactAudienceList: new FormControl(
        data.contactAudienceList ? data.contactAudienceList : ""
      ),
      backGroundImage: new FormControl(
        data.backGroundImage ? data.backGroundImage : null
      ),
      backGroundImageUrl: new FormControl(
        data.backGroundImageUrl ? data.backGroundImageUrl : null
      ),
      message: new FormControl(data.message ? data.message : ""),
      startDate: new FormControl(data.startDate ? data.startDate : ""),
      endDate: new FormControl(data.endDate ? data.endDate : ""),
      // days: new FormControl(data.days ? data.days : ""),
      // hours: new FormControl(data.hours ? data.hours : ""),
      // minutes: new FormControl(data.minutes ? data.minutes : ""),
      // seconds: new FormControl(data.seconds ? data.seconds : ""),
      questions: data?.questions?.length
        ? this.addQuestions(data?.questions)
        : this._fb.array([]),
    });
  }
  private addQuestions(questions: any = []) {
    let questionsFormArr: FormArray = this._fb.array([]);
    for (let index = 0; index < questions.length; index++) {
      const question = questions[index];
      question["questionNo"] = index + 1;
      questionsFormArr.push(this.createQuestionFrom(question));
    }
    return questionsFormArr;
  }
  private createQuestionFrom(data?: any) {
    const formGroup = this._fb.group({
      questionType: new FormControl(
        data?.questionType ? data.questionType : null,
        [Validators.required]
      ),
      question: new FormControl(data?.question ? data.question : null, [
        Validators.required,
      ]),
      image: new FormControl(data?.image ? data.image : null),
      imageUrl: new FormControl(data?.imageUrl ? data.imageUrl : null),
      answers: data?.answers?.length
        ? this.addOptions(data.answers.length, data.answers)
        : this._fb.array([]),
      currentOptionIndex: new FormControl(
        data?.currentOptionIndex ? data.currentOptionIndex : null
      ),
      isOrderText: new FormControl(
        data?.isOrderText ? data?.isOrderText : false
      ),
      isadditionalText: new FormControl(
        data?.isadditionalText ? data?.isadditionalText : false
      ),
      additionalText: new FormControl(
        data?.additionalText ? data?.additionalText : null
      ),
      isOptional: new FormControl(data?.isOptional ? data?.isOptional : false),
      noneOfAbove: new FormControl(
        data?.noneOfAbove ? data?.noneOfAbove : false
      ),
      isArrange: new FormControl(data?.isArrange ? data?.isArrange : false),
      isYesNo: new FormControl(data?.isYesNo ? data?.isYesNo : false),
      selectedAnswer: new FormControl(
        data?.selectedAnswer?.length ? data?.selectedAnswer : []
      ),
      selectedAnswerIndex: new FormControl(this.checkMultiSelectQuestion(data)),
      textAnswer: new FormControl(data?.textAnswer ? data?.textAnswer : null),
      questionNo: new FormControl(data?.questionNo ? data?.questionNo : 0),
      status: new FormControl("pending"),
    });
    return formGroup;
  }
  private checkMultiSelectQuestion(data: any) {
    if (
      data.questionType == this.eQuesTypes.MULTI_SELECT ||
      data.questionType == this.eQuesTypes.SINGLE_SELECT
    ) {
      return data?.selectedAnswerIndex?.length ? data?.selectedAnswerIndex : [];
    }
    return data?.selectedAnswerIndex >= 0 ? data.selectedAnswerIndex : null;
  }
  private addOptions(noOfOptions: number, items?: any[]): FormArray | void {
    let answers: FormArray = this._fb.array([]);
    for (let index = 0; index < noOfOptions; index++) {
      const optionIndex = answers.controls.length
        ? answers.controls[answers.controls.length - 1].get("optionIndex")
          .value + 1
        : index;
      answers.push(
        this.createOption(optionIndex, items?.length ? items[index] : {})
      );
    }
    return answers;
  }
  private createOption(optionIndex: number, item?: any) {
    return this._fb.group({
      optionIndex: new FormControl(optionIndex),
      option: new FormControl(item?.option ? item.option : null),
      image: new FormControl(item?.image ? item.image : null),
      imageUrl: new FormControl(item?.imageUrl ? item.imageUrl : null),
    });
  }
  get allQuestions() {
    return (
      this.surveyForm?.controls ? this.surveyForm?.get("questions") : []
    ) as FormArray;
  }
  get survey() {
    return this.surveyForm?.value;
  }
  get currentQuestionForm() {
    return this.singleQuestion.controls[0] as FormGroup;
  }
  set currentQuestionForm(formGroup: FormGroup) {
    this.singleQuestion.controls[0] = formGroup;
  }

  public setMultiSelectAns(fg: FormGroup, answer: any, index: number) {
    let values = [...fg.value.selectedAnswer];
    let valueIndexes = [...fg.value.selectedAnswerIndex];
    if (!values?.length) {
      fg.get("selectedAnswer").setValue([answer]);
      fg.get("selectedAnswerIndex").setValue([index]);
      return;
    }
    const valueIndex = valueIndexes.findIndex((x) => x == index);
    valueIndex > -1
      ? valueIndexes.splice(valueIndex, 1)
      : valueIndexes.push(index);
    valueIndex > -1 ? values.splice(valueIndex, 1) : values.push(index);

    fg.get("selectedAnswer").setValue([...values]);
    fg.get("selectedAnswerIndex").setValue([...valueIndexes]);
  }
  public setEvaluationAns(fg: FormGroup, selected: any, index: number) {
    const value = `${selected}-${index}`;
    const answer = fg.value.selectedAnswer.includes(value) ? [] : [value];
    fg.get("selectedAnswer").setValue(answer);
    fg.get("selectedAnswerIndex").setValue(index);
  }
  public setSingleQuestionAns(fb: FormGroup, selected: any, index: number) {
    const answer = fb.value.selectedAnswer.includes(selected) ? [] : [selected];
    const answerIndex = fb.value.selectedAnswerIndex.includes(index)
      ? []
      : [index];
    fb.get("selectedAnswer").setValue(answer);
    fb.get("selectedAnswerIndex").setValue(answerIndex);
  }
  public onTextAnswerChange(fb: FormGroup, answer: string) {
    fb.get("selectedAnswer").setValue(answer?.length ? [answer] : []);
  }
  public setAnswerYesNo(fb: FormGroup, answer: string) {
    fb.get("selectedAnswer").setValue([answer]);
  }

  private finish() {
    this.showLoader = true;
    let data = {
      ...this.survey,
      gender: this.gender ? this.gender : "male",
      surveyId: this.surveyId,
      userId: this.userId,
    };

    this.surveyService
      .answerSurvey(data)
      .pipe()
      .subscribe(
        (result) => {
          this.showLoader = false;
          localStorage.removeItem("Kafu.KF-modal-survey");
          this._router.navigate(["/survey/thankyou", btoa(this.surveyId)]);
        },
        (err) => {
          this.showLoader = false;
          this.translateService
            .get("general.someThingWentWrongPleaseTryLater")
            .subscribe((text) => {
              this.toasterService.error(text);
            });
        }
      );
  }

  public async getSurveyById() {
    if (this._ac.snapshot.params.surveyId) {
      const surveyId = this._ac.snapshot.params.surveyId;
      const survey = await this.surveyService
        .getSurveyById(surveyId)
        .toPromise();
      this.surveyService.createLocalSurveyModal(survey);
      const verifyModel = this.bsModalService.show(SurveyNameComponent, {
        class: "modal-dialog-centered sign-up c-survey-modal",
        backdrop: "static",
        ignoreBackdropClick: true,
        keyboard: false,
      });
      verifyModel.content.allowClose = false;
      verifyModel.content.onClose = async (result) => {
        if (result) {
          const surveyId = this._ac.snapshot.params.surveyId;
          const survey = await this.surveyService
            .getSurveyById(surveyId)
            .toPromise();
          if (!survey) {
            this.translateService
              .get("survey.surveyNotFound")
              .subscribe((text) => {
                this.toasterService.error(text);
              });
            this._router.navigate(["/"]);
            return;
          }

          this.surveyService.createLocalSurveyModal(survey);
          this.surveyInIt();
          verifyModel.hide();
        }
      };
    }
  }

  public scroll(id: string) {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }

  public openPreviewModal(url: string) {
    if (url) {
      const modalRef = this.bsModalService.show(ImagePreviewerComponent, {
        backdrop: "static",
        class: "preview-modal-container modal-dialog-centered",
        initialState: {
          imageUrl: url,
        },
      });

      modalRef.content.onClose = (auth) => {
        modalRef.hide();
      };
    }
  }

  public nextQuestion(nextQuestionNo: number) {
    this.singleQuestion = this._fb.array([]);
    this.hideQuestion();
    if (nextQuestionNo <= this.allQuestions.length) {
      const question = this.allQuestions.controls.find(
        (x) => x.value.questionNo === nextQuestionNo
      );
      this.currentQuestionForm = this.createQuestionFrom(
        this.homeService.deepCopy(question.value)
      );
      this.currentQuestionNo = nextQuestionNo;
    }
    this.showQuestionAnimation();
  }

  public previousQuestion() {
    this.questionValidation = false;
    const questionNo = this.currentQuestionForm.value.questionNo - 1;
    if (questionNo === 0) {
      this.toasterService.error("No Previous Question");
    }
    console.log(questionNo, "questionNo");
    this.nextQuestion(questionNo);
    this.showQuestionAnimation();
  }

  public questionSubmit(isLast?: boolean) {
    const question = { ...this.currentQuestionForm.value };
    if (!question.isOptional && !question.selectedAnswer.length) {
      this.questionValidation = true;
      return;
    }

    this.questionValidation = false;
    const questionNo = question.questionNo;
    this.currentQuestionForm.get("status").setValue("submitted");
    this.allQuestions.controls[questionNo - 1].patchValue({
      ...this.homeService.deepCopy(this.currentQuestionForm.value),
    });

    isLast ? this.finish() : this.nextQuestion(questionNo + 1);
  }

  public onStartSurveyClick() {
    this.displayStates.invite = false;
    this.displayStates.question = true;
  }
  public onLeavePageClick() {
    localStorage.removeItem("Kafu.KF-modal-survey");
    this._router.navigate(["/"]);
  }

  private hideQuestion() {
    this.questionAnimationClass = "d-none";
  }

  private showQuestionAnimation() {
    setTimeout(() => {
      this.questionAnimationClass = "question-slide-animate";
    }, 200);
  }
}
