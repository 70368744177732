<div class="modal-body">
  <div class="d-flex text-center">
    <img class="_img" src="assets/imgs/welcome.png" alt="" />
    <!-- <h1>welcome
      <span>to kafu survey</span>
    </h1> -->
    <p>
      "{{ "profile.complete and get" | translate }} <b>200</b
      >{{ "profile.for signup" | translate }} "
    </p>
    <a (click)="goComplete()" class="m-btn m-btn-green _btn"
      >{{ "profile.Complete your profile" | translate }}
    </a>
  </div>
</div>
