import { Injectable } from "@angular/core";
import { WebApiService } from "./webApi.service";

import { LocalStorageService } from "angular-2-local-storage";
@Injectable()
export class PlanService {
  constructor(
    private webApi: WebApiService,
    private localStorageService: LocalStorageService
  ) { }

  getPlans() {
    return this.webApi.get(`/plan/all`);
  }
  getMemberShip() {
    return this.webApi.get(`/member-ship/all`);
  }
  postPlan(id: any) {
    return this.webApi.post(`/user/subscribe/${id}`);
  }
  createLocalPlanModal(plan: any) {
    this.localStorageService.set("KF-modal-survey", plan);
  }
  getLocalPlanModal() {
    return this.localStorageService.get("KF-modal-survey") as any;
  }
}
