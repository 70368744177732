import { Component, OnInit } from '@angular/core';
import { eLanguage } from 'src/app/general/enums/language.enum';
import { LanguageService } from 'src/services/language.service';
import { NotificationService } from 'src/services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  notifications: any[] = []
  lang: any

  constructor(private _ns: NotificationService, public languageService: LanguageService,
  ) {
    this.lang =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
  }

  ngOnInit(): void {
    this.getNotifications();
    // this.notifications = [
    //   {
    //     title: 'Survey Created',
    //     description: 'you have created a survey'
    //   },
    //   {
    //     title: 'Points',
    //     description: 'Kafu App Please Complete your Profile to get free 500 Point'
    //   },
    //   {
    //     title: 'Points',
    //     description: 'Kafu App Please Complete your Profile to get free 500 Point'
    //   },
    //   {
    //     title: 'Points',
    //     description: 'Kafu App Please Complete your Profile to get free 500 Point'
    //   },
    //   {
    //     title: 'Points',
    //     description: 'Kafu App Please Complete your Profile to get free 500 Point'
    //   },
    // ]
  }

  getNotifications() {
    this._ns.getNotifications().subscribe(x => {
      if (x?.length) {
        this.notifications = x;
        this.sortPR();
      }
    })
  }
  sortPR() {
    this.notifications.sort((a, b) => {
      let fa = a.createdAt, fb = b.createdAt;
      if (fa < fb) {
        return 1;
      }
      if (fa > fb) {
        return -1;
      }
      return 0;
    });
  }
}
