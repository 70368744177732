import { LoginComponent } from "./../../partials/login/login.component";
import { first } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { SurveyService } from "src/services/survey.service";
import * as moment from "moment";
import { HomeService } from "src/services/home.service";
import { AuthService } from "src/services/auth.service";
import { BsModalService } from "ngx-bootstrap/modal";
import { LanguageService } from "../../../services/language.service";
import { CreateSurveyComponent } from "../create-survey/create-survey.component";
import { Router } from "@angular/router";
import { SurveyNameComponent } from "../survey-name/survey-name.component";
import { eLanguage } from "src/app/general/enums/language.enum";
import { DarkLiteThemeService } from "src/services/dark-lite-themeService.service";
import { SurveyTypes } from "src/app/general/enums/survey-types.enum";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

declare const gtag: Function;

@Component({
  selector: "app-all-surveys",
  templateUrl: "./all-surveys.component.html",
  styleUrls: ["./all-surveys.component.css"],
})
export class AllSurveysComponent implements OnInit {
  count = 0;
  currentPage = 0;
  limit = 9;
  isSidebarVisible = true;
  surveys: any[] = []; // Your survey data
  user: any;
  showLoader = false;
  survey = {
    all: [],
    featured: [],
    mostViewed: [],
    answers: [],
    latest: [],
    polls: [],
    allFeatured: [],
    allMostViewed: [],
    allAnswers: [],
    allLatest: [],
    allPolls: [],
  };
  earnPointsSliderConfig: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    animateOut: "fadeOut",
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    navSpeed: 700,
    nav: true,
    navText: [
      "<i class='fa fa-arrow-left'></i>",
      "<i class='fa fa-arrow-right'></i>",
    ],
  };
  statisticsConfig: OwlOptions = {
    loop: false,
    nav: true,
    navText: [
      "<i class='fas fa-angle-left'></i>",
      "<i class='fas fa-angle-right'></i>",
    ],
    dots: false,
    margin: 20,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 2,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 6,
      },
    },
  };
  statistics = [];

  partnersConfig: OwlOptions = {
    loop: false,
    nav: true,
    navText: [
      "<i class='fas fa-angle-left'></i>",
      "<i class='fas fa-angle-right'></i>",
    ],
    dots: false,
    margin: 20,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 5,
      },
    },
  };
  slideConfig: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    autoplay: true,
    pullDrag: false,
    animateOut: "fadeOut",
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
        dots: false,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    nav: false,
  };
  language: string = eLanguage.English;

  onClose: () => void;
  constructor(
    private router: Router,
    private toaster: ToastrService,
    public authService: AuthService,
    private homeService: HomeService,
    private modalService: BsModalService,
    private surveyService: SurveyService,
    public languageService: LanguageService,
    private translateService: TranslateService,
    public darkThemeService: DarkLiteThemeService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.language =
      this.languageService.getLanguageOrDefault() === eLanguage.Short_Arabic
        ? eLanguage.Arabic
        : eLanguage.English;
    this.user = this.authService.accessToken ?? null;

    if (this.language === eLanguage.Arabic) {
      this.slideConfig.rtl = true;
      this.earnPointsSliderConfig.rtl = true;
      this.statisticsConfig.rtl = true;
      this.partnersConfig.rtl = true;
    }
  }
  ngOnInit(): void {
    this.getAllSurveys(this.currentPage, this.limit);
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .subscribe((result) => {
        if (result.matches) {
          this.isSidebarVisible = false;
        }
      });
  }
  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible;
  }
  getAllSurveys(page, limit) {
    this.showLoader = true;
    let data = [];

    this.surveyService.getAllSurvey(page, limit, "isFeatured").subscribe(
      (result) => {
        data = result.list;
        this.count = result.count;
        this.currentPage = result.currentPage;

        this.survey.all = data?.length ? this.homeService.deepCopy(data) : [];
        this.filterFeaturedSurvey();
        if (this.survey.all.length) {
          // this.filterMostViwedSurvey();
          // this.filterAnwseredSurvey();
          // this.filterLatestSurvey();
          // this.filterPollSurvey();
        }
        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
      }
    );
  }
  tabChange(page, limit, key) {
    this.showLoader = true;
    console.log(
      "🚀 ~ file: not-loggedin-home.component.ts:411 ~ NotLoggedinHomeComponent ~ tabChange ~ key:",
      key
    );
    let data = [];
    this.surveyService.getAllSurvey(page, limit, key).subscribe(
      (result) => {
        data = result.list;
        this.count = result.count;
        this.currentPage = result.currentPage;

        this.survey.all = data?.length ? data : [];
        if (this.survey.all.length) {
          if (key == "isFeatured") {
            this.filterFeaturedSurvey();
          } else if (key == "isPublished") {
            this.filterAnwseredSurvey();
            this.filterMostViwedSurvey();
          } else {
            this.filterLatestSurvey();
          }
          // this.filterAnwseredSurvey();
          // this.filterPollSurvey();
        }
        this.showLoader = false;
      },
      (err) => {
        this.showLoader = false;
      }
    );
  }
  calculateAgeCategory(birthdate) {
    // Parse the birthdate string into a Date object
    const birthDateObj = new Date(birthdate);

    // Get the current date
    const currentDate = new Date();

    // Calculate the user's age
    const age = currentDate.getFullYear() - birthDateObj.getFullYear();

    // Define age categories
    const ageCategories = [
      "9 - 12 Years",
      "13 - 17 Years",
      "18 - 29 Years",
      "30 - 45 Years",
      "45 - 60 Years",
      "60 plus Years",
    ];

    // Determine the age category
    if (age >= 9 && age <= 12) {
      return ageCategories[0];
    } else if (age >= 13 && age <= 17) {
      return ageCategories[1];
    } else if (age >= 18 && age <= 29) {
      return ageCategories[2];
    } else if (age >= 30 && age <= 45) {
      return ageCategories[3];
    } else if (age >= 45 && age <= 60) {
      return ageCategories[4];
    } else {
      return ageCategories[5];
    }
  }

  // Example usage

  getAnsweredSurveys() {
    this.surveyService.getAllFilledSurvey().subscribe((x) => {
      if (x?.length) {
        this.survey.allAnswers = x;
        this.loadMoreSurvey("answers", "allAnswers");
      }
    });
  }
  async newLoadMore(
    key: string,
    getKey: any,
    masterKey: any,
    currentPage?: number
  ) {
    this.showLoader = true;

    let surveys = [];

    try {
      const result = await this.surveyService
        .getAllSurvey(currentPage + 1, 6, getKey)
        .toPromise();

      // Add new data to the surveys array
      surveys = [...masterKey, ...result.list /* Add your new data here */];

      debugger;
      this.count = result.count;
      this.currentPage = result.currentPage;
      this.showLoader = false;
    } catch (error) {
      // Handle error if needed
      console.error("Error fetching survey data:", error);
    }

    console.log(
      "🚀 ~ file: not-loggedin-home.component.ts:430 ~ NotLoggedinHomeComponent ~ newLoadMore ~ surveys:",
      surveys
    );

    this.survey[key] = this.homeService.deepCopy(surveys);
  }
  filterFeaturedSurvey() {
    this.survey.allFeatured = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("featured", "allFeatured");
  }

  filterMostViwedSurvey() {
    this.survey.allMostViewed = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("mostViewed", "allMostViewed");
  }

  filterLatestSurvey() {
    this.survey.allLatest = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("latest", "allLatest");
  }

  filterAnwseredSurvey() {
    this.survey.allAnswers = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("answers", "allAnswers");
  }

  filterPollSurvey() {
    this.survey.allPolls = this.homeService.deepCopy(this.survey.all);
    this.loadMoreSurvey("polls", "allPolls");
  }

  loadMoreSurvey(key: string, masterKey: string) {
    const surveyCount = this.survey[key].length + 2;
    if (this.survey[masterKey].length >= surveyCount) {
      this.survey[key] = this.homeService.deepCopy(this.survey[masterKey]);
      return;
    }
    let surveys = [];
    for (let i = 0; i < surveyCount; i++) {
      const survey = this.survey[masterKey][i];
      if (survey) {
        surveys.push(survey);
      }
    }
    this.survey[key] = this.homeService.deepCopy(surveys);
  }

  createSurvery() {
    // if (this.authService.currentUser?._id) {
    this.surveyService.removeBrand();
    const verifyModel = this.modalService.show(CreateSurveyComponent, {
      // class: "modal-dialog-centered sign-up c-survey-modal",
      class:
        "modal-lg modal-dialog-centered sign-up c-survey-modal  create-survey-modal",
      backdrop: "static",
    });
    verifyModel.content.onClose = (auth) => {
      verifyModel.hide();
    };
    // } else {
    //   const loginModel = this.modalService.show(LoginComponent, {
    //     class: "modal-dialog-centered login",
    //     backdrop: "static",
    //   });
    //   loginModel.content.onClose = (auth) => {
    //     loginModel.hide();
    //   };
    // }
  }

  startSurvey(survey: any) {
    const id = survey.id;
    const lang = survey.language;
    if (
      this.authService.currentUser &&
      this.authService.currentUser?._id &&
      !this.authService.currentUser?.isProfileCompleted
    ) {
      this.router.navigate(["/profile"]);
    } else {
      const eventAction = survey?.title || "Survey_Clicked";

      gtag("event", eventAction, {
        event_category: "Button",
        event_label: "Survey Clicked",
      });
      this.surveyService
        .ClickedSurvey(survey)
        .pipe(first())
        .subscribe((result) => {
          console.log("result", result);
        });
      if (
        this.authService.currentUser &&
        this.authService.currentUser?.isProfileCompleted
      ) {
        this.surveyService.createSurveyUserInfo({
          name: this.authService.currentUser.firstName,
          gender: this.authService.currentUser?.gender,
          cityId: this.authService.currentUser?.city,
          country: this.authService.currentUser?.country,
          age: this.calculateAgeCategory(
            this.authService.currentUser?.birthdate
          ),
        });
        this.surveyService
          .getSurveyById(id)
          .pipe(first())
          .subscribe((result) => {
            this.showLoader = true;
            let values = {
              ...survey,
              ...result,
            };
            this.surveyService.createLocalSurvey(result);
            this.surveyService.createLocalSurveyModal(values);
            if (survey?.surveyType == SurveyTypes.NORMAL) {
              this.router.navigate([`/answer-new-ui/${survey?.language}`]);
            } else if (survey?.surveyType == SurveyTypes.ENTERPRISE) {
              this.router.navigate([
                `enterprise/survey-answer/${survey?.language}`,
              ]);
            } else {
              this.translateService
                .get("survey.surveyTimeNotValid")
                .subscribe((text) => {
                  this.toaster.error(text);
                });
            }
          });
      } else {
        this.surveyService
          .getSurveyById(id)
          .pipe(first())
          .subscribe((result) => {
            // this.showLoader = true;
            let values = {
              ...survey,
              ...result,
            };
            this.surveyService.createLocalSurveyModal(values);

            if (survey.surveyType == "Enterprise Survey") {
              this.router.navigate(["/surveys/enterprise"]);
            } else {
              this.router.navigate([`/survey/start/${id}/${lang}`]);
            }
          });
      }
    }
  }

  viewSurvey(survey: any) {
    console.log("survey: ", survey);
    this.router.navigate(["/survey/preview", btoa(survey.id)]);
  }
}
